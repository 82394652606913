.plant-threat-selector {
  display: flex;

  &__open-catalog-btn {
    margin-left: 9px;
    margin-right: -12px;
    outline: none;

    &:hover,
    &:focus,
    &:active:focus {
      outline: none;
    }
  }

  &__open-catalog-btn-container {
    align-items: center;
    display: flex;
  }

  &__select {
    flex: 1;
  }

  &__open-catalog-icon {
    font-size: 20px;
  }

  &__select-label {
    align-items: center;
    color: #000;
    display: flex;
    font-size: 13px;
    height: 50px;
    letter-spacing: 0.03px;
    line-height: 15px;
  }

  &__select-label-description {
    margin-left: 10px;
  }

  &__select-label-image {
    border-radius: 2px;
    height: 40px;
    width: 40px;
  }

  &__catalog {
    .plant-threats-catalog {
      height: 600px;
    }

    .plant-threats-catalog__left-column {
      width: 342px;
    }

    .plant-threats-catalog__card-wrapper {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 30px;
    }

    .plant-threats-catalog__search {
      padding-top: 13px;
    }
  }
}
