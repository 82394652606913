.avizio-growth-phases-automatic-alert {
  &__wrapped-form-item {
    margin-bottom: 0;

    .ant-form-item-control {
      padding: 0 5px;
    }
  }

  &__name-input {
    margin-bottom: 30px;
  }
}
