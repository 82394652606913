.plant-threats-catalog {
  display: flex;
  flex-grow: 1;

  &__left-column {
    border-right: 2px solid #eee;
    display: flex;
    flex-direction: column;
    width: 382px;
  }

  &__list {
    flex: 1;
    overflow: hidden;
  }

  &__item,
  &__search {
    border-bottom: 1px solid #eee;
  }

  &__search {
    padding-left: 15px;
    padding-right: 26px;
    padding-top: 24px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
  }

  &__search-input {
    margin-top: 25px;
  }

  &__search-help-block {
    margin-bottom: 22px;
  }

  &__group-item {
    border-bottom: 1px solid #eee;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    line-height: 75px;
    padding-left: 12px;
  }

  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 75px;
    justify-content: flex-start;
    outline: none;
    padding-left: 12px;
    padding-right: 16px;
    position: relative;
  }

  &__item:hover {
    background-color: #eee;
  }

  &__item_selected {
    color: #08c;
  }

  &__item-image {
    background-color: #fff;
    border: solid 1px #eee;
    border-radius: 2px;
    margin-right: 16px;
  }

  &__item-image_selected {
    border: solid 2px #08c;
    padding: 2px;
  }

  &__item-name {
    font-size: 15px;
  }

  &__item-latin-name {
    color: #999;
    font-size: 13px;
  }

  &__item-latin-name_selected {
    color: #08c;
  }

  &__item-threat-type-icon {
    margin-left: auto;
  }

  &__item-icon {
    height: 20px;
    outline: none;
    text-align: center;
    width: 20px;
  }

  &__card-wrapper {
    flex: 1;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 38px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 30px;
  }

  &__card-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__card-name {
    color: #000;
    font-size: 20px;
    line-height: 20px;
  }

  &__card-latin-name {
    color: #999;
    font-size: 16px;
    height: 16px;
    line-height: 16px;
    margin-top: 5px;
  }

  &__main-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  &__main-info-table {
    padding-left: 30px;
  }

  &__gallery {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  &__gallery-preview-row {
    display: flex;
    flex-direction: row;
  }

  &__gallery-preview-button {
    cursor: pointer;
    margin-right: 12px;
    margin-top: 11px;
    outline: none;
  }

  &__gallery-preview-button_selected {
    border: solid 2px #0071cd;
    padding: 2px;
  }

  &__gallery-preview-button_selected > &__gallery-preview-image {
    border: 0;
    height: 42px;
    width: 42px;
  }

  &__gallery-preview-image {
    border: solid 1px #eee;
    border-radius: 2px;
    vertical-align: bottom;
  }

  &__gallery-main-photo {
    border: solid 1px #eee;
    border-radius: 2px;
    height: 236px;
    width: 236px;
  }

  &__attributes {
    font-size: 15px;
    line-height: 19px;
    margin-top: 10px;
  }

  &__attributes_bold {
    display: inline-block;
    font-weight: 600;
  }

  &__attributes_normal {
    display: inline-block;
    margin-top: 7px;
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
  }

  &__attributes_big-label {
    display: inline-block;
    font-size: 20px;
    font-weight: normal;
    padding-bottom: 6px;
  }

  &__no-results {
    align-items: center;
    display: flex;
    font-size: 13px;
    height: 75px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
}
