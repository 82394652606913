.crop-rotation {
  margin-top: 16px;

  &__edit-history-item-modal {
    .spin-container {
      display: flex;
      justify-content: center;
    }

    .gutter-row {
      flex: 1;
      max-width: 100%;
    }
  }

  // ignoring reasons: restyling antd table elements
  // scss-lint:disable QualifyingElement
  td.no-padding-cell {
    // scss-lint:disable ImportantRule
    padding: 0 !important;
  }

  &__sowing-filter {
    margin-bottom: 4px;
  }

  &__history-item-container {
    cursor: pointer;
    // scss-lint:disable ImportantRule
    padding: 4px 8px !important;
    transition: background-color 0.2s ease;

    &:first-child {
      padding-top: 8px !important;
    }

    &:last-child {
      padding-bottom: 8px !important;
    }

    &:hover {
      background-color: #00000017;
    }

    &-faded {
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    &-hovered-id {
      background-color: #00000017;
    }
  }

  &__text-cell {
    align-items: center;
    display: flex;
    font-size: 12px;
    min-height: 44px;
    vertical-align: middle;
  }

  &__separator {
    margin: 16px 0;
  }

  &__columns-config {
    // scss-lint:disable ImportantRule
    // REASON: even though there is an official example of how to use Checkbox.Group with Grid
    // it in fact does not work when multiple Rows specified. Maybe antd bug.
    // (see: https://ant.design/components/checkbox#checkbox-demo-layout)
    // Removing its ``display: flex`` property fixes usage with mutltiple Rows.
    display: block !important;
  }

  .ant-table-cell {
    // scss-lint:disable ImportantRule
    vertical-align: middle !important;
  }

  &__area-type-subtitle {
    color: rgba(0, 0, 0, 0.541);
    font-size: 10px;
  }

  &__area-cell {
    .ant-col {
      font-size: 12px;
      line-height: 1.45;
    }

    .archived-area {
      color: #0000004c;
    }

    .total-area {
      font-weight: bold;
    }
  }

  &__total-row {
    font-weight: bold;
  }

  .crop-cell {
    &__content {
      border-radius: 4px;
      // scss-lint:disable ImportantRule
      box-shadow: none !important;
      min-height: 44px;
      width: 100%;

      // scss-lint:disable SelectorDepth
      .ant-card-body {
        // scss-lint:disable ImportantRule
        padding: 6px !important;
      }
    }

    &-suggest_ai {
      border: 1px solid #777;
      color: #777;
    }

    &-use_ai {
      border: 1px solid #333;
      color: #333;
    }

    &-conflict_ai {
      border: 1px solid #f44336;
    }

    &-success_ai {
      border: 1px solid #4cae4c;
    }

    &__row {
      align-items: center;
      display: flex;
      min-height: 32px;
    }

    &__crop-name {
      font-size: 11px;
    }

    &__crop-no-name {
      font-size: 14px;
      padding-left: 2px;
    }

    &__icon {
      margin-right: 4px;
    }

    &__archive-label {
      background-color: #f44336;
      border-radius: 2px;
      bottom: 1px;
      color: #ffdbdb;
      font-size: 8px;
      padding: 0 2px;
      position: absolute;
      right: 1px;
    }

    &__old-crop-label {
      background-color: #1c1c1c73;
      border-radius: 2px;
      color: #ffffff;
      font-size: 11px;
      padding: 0 2px;
      position: absolute;
      right: 2px;
      top: 2px;
    }
  }

  .icon-ai {
    background-repeat: no-repeat;
    height: 10px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 15px;

    &-use_ai {
      background-image: url("./nested/icons/ai.svg");
    }

    &-suggest_ai {
      background-image: url("./nested/icons/ai-grey.svg");
    }

    &-conflict_ai {
      background-image: url("./nested/icons/ai-red.svg");
    }

    &-success_ai {
      background-image: url("./nested/icons/ai-green.svg");
    }
  }
}
