.grain-hub {
  &__crop-filter {
    font-size: 13px;
    font-weight: normal;
    line-height: 13px;
    width: 190px;
  }

  .fields-rating-table {
    padding-bottom: 0;
  }

  .fields-rating-table__table {
    border-bottom: 1px solid #c9c9c9;
  }

  .crops-summary-table__widget {
    overflow: visible;
  }

  &__crop-filter-item {
    align-items: center;
    display: flex;
    height: 34px;
  }

  &__crop-filter-item-image {
    height: 23px;
    margin-right: 7px;
    width: 23px;
  }

  &__crop-filter-item-name {
    color: #555;
    font-size: 13px;
    font-weight: normal;
    line-height: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__fields-table-th {
    color: #777;
    font-size: 11px;
    font-weight: normal;
    padding: 8px;
    vertical-align: bottom;

    &-name {
      width: 200px;
    }

    &-value {
      text-align: right;
    }

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  &__fields-table-group-tr {
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &_selected {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &__fields-table-group-tr_selected &__fields-table-group-name {
    border-bottom: 0;
    color: #333;
    font-size: 13px;
  }

  &__fields-table-group-tr_selected &__fields-table-group-td {
    border-top: 1px solid #c9c9c9;
  }

  &__fields-table-group-td {
    border-top: 1px solid rgba(0, 0, 0, 0.02);
    color: #333;
    font-size: 13px;
    padding: 8px;
    vertical-align: top;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }

    &-value {
      text-align: right;
    }
  }

  &__fields-table-group-name {
    border-bottom: dotted 1px #aaa;
    color: #0071cd;
  }

  &__fields-table-tr {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &:hover {
      background-color: #e4e4e4;
    }
  }

  &__fields-table-td {
    padding: 4px;
    vertical-align: top;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }

    &-value {
      color: #333;
      font-size: 13px;
      text-align: right;
    }
  }

  &__fields-table-field-group-name {
    color: #777;
    font-size: 11px;
  }

  &__field-table-field-name {
    color: #347ab9;
    font-size: 13px;
  }

  &__fields-table-show-more {
    height: 40px;
    vertical-align: middle;

    &:hover {
      background-color: #e4e4e4;
    }
  }

  &__fields-table-header {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__admin-region-popup {
    background-color: #08c;
    color: #fff;
    opacity: 0.8;
    padding-bottom: 15px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 100;

    // make larger for big screens
    @media (min-width: 768px) {
      width: 270px;
    }
  }

  &__admin-region-popup-header {
    font-size: 18px;
    line-height: 1em;
    margin-top: 2px;
  }

  &__admin-region-popup-value-name {
    color: #fff;
    font-size: 11px;
    line-height: 1em;
    margin-bottom: 0;
    margin-top: 12px;
  }

  &__admin-region-popup-value {
    color: #fff;
    font-size: 16px;
    line-height: 1em;
    margin-bottom: 0;
    margin-top: 7px;
  }
}
