.alert-row {
  &__machine_avatar {
    display: inline-block;
    margin-right: 4px;
    vertical-align: top;
  }

  &__machine_info {
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    margin-top: 2px;
  }
}
