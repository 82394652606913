.map-discrete-ndvi-scale {
  &__dark-transparent {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    padding: 4px;

    &_custom {
      padding: 0 4px 12px;
    }

    &__table {
      line-height: 1.4;
    }

    &__color,
    &__value {
      font-size: 9px;
      padding-left: 3px;
    }

    &__value_custom {
      line-height: 0;
      vertical-align: bottom;
    }
  }
}
