.field-dashboard-variety-select {
  &:hover {
    background-color: #fcf1cf;
  }

  .ant-select {
    height: auto;
  }

  // scss-lint:disable ImportantRule, SelectorDepth
  // reason: antd table styles are hard to override
  .ant-select-selector {
    padding: 0 !important;
  }

  .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.88) !important;
    font-size: 13px;
    line-height: 1.428571429 !important;
    white-space: normal !important;
  }

  .ant-select-selector::after {
    line-height: 0 !important;
  }

  .ant-select-selection-placeholder {
    line-height: 1.428571429 !important;
  }
  // scss-lint:enable ImportantRule, SelectorDepth
}
