.no-records {
  padding: 20px;
  text-align: center;

  &__title {
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 12px;
  }

  &__create-btn {
    border-radius: 2px;
    box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.3);
    font-weight: bold;
    min-width: 260px;
  }
}
