.plant-threat-scores-automatic-alert {
  &__wrapped-form-item {
    margin-bottom: 0;

    .ant-form-item-control {
      padding: 0 5px;
    }
  }

  &__hint-text {
    padding-top: 16px;
  }

  &__name-input {
    margin-bottom: 30px;
  }

  &__name-input-wrapper {
    position: relative;
  }

  &__name-input-hint {
    bottom: 0;
    left: 0;
    margin-bottom: -32px;
    position: absolute;

    .ant-btn {
      padding: 0;
    }
  }
}
