.react-select-field-option-label {
  color: #333;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;

  &_disabled {
    color: rgba(0, 0, 0, 0.25);
  }

  &__column-main {
    min-width: 120px;
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__column-additional {
    margin-left: auto;
    min-width: 120px;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
