.plant-threat-scores {
  &__table-search {
    margin: 4px 16px 16px;
    max-width: 30%;
  }

  &__calendar-controls {
    // scss-lint:disable ImportantRule
    width: auto !important;
  }

  &__expanded-row {
    >.ant-table-cell {
      // scss-lint:disable ImportantRule
      background-color: #fffcee !important;
      padding-left: 0 !important;
    }

    .ant-table-cell {
      background-color: #fffcee;
    }

    th {
      // scss-lint:disable ImportantRule
      background-color: #fff8d3 !important;
    }
  }

  &__table {
    .ant-table {
      // scss-lint:disable ImportantRule
      margin-left: 0 !important;
    }
  }

  &__score {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 45px;
    text-align: center;
    vertical-align: middle;
  }

  &__provider {
    min-width: 110px;
  }

  &__expanded-base-row {
    // scss-lint:disable ImportantRule
    background-color: #fffbe6 !important;
  }
}

// scss-lint:disable QualifyingElement
th.plant-threat-scores__table-header {
  vertical-align: middle;
}
