.field-scout-report-threat-mapping-item {
  &__search,
  &__row {
    background-color: #f5f5f5;
    padding: 12px 20px 15px;
  }

  &__search-header {
    font-weight: bold;
    padding-bottom: 6px;
    padding-top: 4px;
  }

  &__help-block {
    margin-bottom: 0;
  }

  &__row {
    margin-bottom: 4px;

    .form-group {
      margin: 0 !important; // scss-lint:disable ImportantRule
    }
  }

  &__row-name {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 6px;
    padding-left: 0;
    padding-right: 5px;
    padding-top: 4px;
  }
}
