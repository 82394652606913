.soil-dashboard-map-widget {
  padding: 10px;

  &__panel {
    box-shadow: unset;
    margin: 0;
    padding: 0;

    h3 {
      color: #777777;
      font-size: 13px;
      height: 17px;
      line-height: 132%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__panel-heading {
    margin: 0;
    padding: 12px;
    padding-right: 10px;
  }

  &__panel-body {
    bottom: 40px;
    display: block;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    padding-left: 20px;
    padding-right: 5px;
    position: absolute;
    right: 0;
    top: 52px;
  }

  &__panel-footer {
    background: transparent;
    border: 0;
    bottom: 0;
    left: 0;
    padding: 5px;
    padding-left: 20px;
    position: absolute;
    right: 0;
  }

  &__panel-footer-link {
    line-height: 30px;
  }

  &__button {
    font-size: 13px;
    padding: 6px;
  }

  .info-row {
    margin: 5px 0;
  }
}

// ################
// Soil texture map
// ################

.soil-dashboard-texture-map {
  &__map {
    height: 350px;
    width: 100%;
  }

  &__panel {
    box-shadow: unset;
    margin: 0;
    padding: 0;
  }

  &__panel-heading {
    height: 52px;
  }

  &__panel-body {
    bottom: 30px;
    display: block;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 52px;
  }

  &__panel-footer {
    bottom: -5px;
    left: 0;
    position: absolute;
    right: 0;
  }
}

.soil-dashboard-no-texture-maps {
  &__body {
    text-align: center;
  }

  &__no-textures-message {
    margin-bottom: 24px;
  }

  &__left-button {
    margin-right: 30px;
  }

  &__fields-sample {
    background: transparent url("./fields-sample.svg") no-repeat center;
    height: 100px;
    margin-bottom: 30px;
  }
}

// #########
// Soil test
// #########


.soil-dashboard-no-soil-tests {
  &__body {
    text-align: center;
  }

  &__no-tests-message {
    margin-bottom: 24px;
  }

  &__left-button {
    margin-right: 30px;
  }

  &__soil-sampler {
    background: transparent url("./soil-sampler.svg") no-repeat center;
    height: 100px;
    margin-bottom: 30px;
  }
}

.soil-dashboard-soil-test-table {
  &__heading  th {
    display: inline-block;
  }

  &__element {
    padding-left: 0 !important;  // scss-lint:disable ImportantRule
    width: 60%;
  }

  &__concentration {
    width: 6%;
  }

  &__average {
    width: 34%;
  }

  &__row {
    cursor: pointer;

    &_selected {
      background-color: rgb(233, 233, 233);
    }

    &:hover {
      background-color: #ddd;
    }
  }

  tr {
    display: flex;
  }
}

// ###########
// Soil relief
// ###########

.soil-dashboard-no-relief {
  &__body {
    height: 270px;
    text-align: center;
  }

  &__no-relief-message {
    margin-bottom: 24px;
  }

  &__image {
    background: transparent url("./relief.svg") no-repeat center;
    height: 100px;
    margin-bottom: 30px;
  }
}

// #############
// Sampling task
// #############

.soil-dashboard-sampling-task {
  &__fixed-map {
    height: 350px;
    width: 100%;
  }
}

.soil-dashboard-no-sampling-tasks {
  &__body {
    text-align: center;
  }

  &__no-tasks-message {
    margin-bottom: 24px;
  }

  &__agro-board {
    background: transparent url("./agro-board.svg") no-repeat center;
    height: 100px;
    margin-bottom: 30px;
  }
}

// ################
// Soil description
// ################

.soil-dashboard-soil-description {
  &__body {
    padding-top: 10px !important; // scss-lint:disable ImportantRule
  }

  &__title {
    padding: 5px;

    &:hover {
      color: #0071cd;
      cursor: pointer;
    }
  }

  &__image {
    padding-top: 5px;
  }

  &__text {
    padding: 0 5px;
  }

  &__info-icon {
    padding-left: 5px;
  }
}

// #############
// Soil moisture
// #############

.soil-dashoard-soil-moisture-widget {
  &__body {
    align-items: center;
    display: flex;
    height: 270px;
    justify-content: center;
  }

  &__image {
    background: transparent url("./soil.png") no-repeat;
    background-size: contain, cover;
    height: 205px;
    width: 130px;
  }

  &__values {
    flex-basis: 10%;
    text-align: right;
  }

  &__image-container {
    flex-basis: 15%;
    margin: 5px;
  }

  &__scales {
    flex-basis: 25%;
    font-size: 12px;
  }

  &__right-lines,
  &__left-lines {
    flex-basis: 5%;

    div {
      border: 1px solid #777777;
    }
  }

  &__left-lines div {
    border-right: 0;
  }

  &__right-lines div {
    border-left: 0;
  }

  &__first-row {
    height: 16px;
    line-height: 16px;
    margin: 8px 4px;
  }

  &__second-row {
    height: 28px;
    line-height: 28px;
    margin: 8px 4px;
  }

  &__third-row {
    height: 38px;
    line-height: 38px;
    margin: 8px 4px;
  }
}

.soil-dashboard-no-soil-moisture {
  &__body {
    height: 270px;
    text-align: center;
  }

  &__no-soil-moisture-message {
    margin-bottom: 24px;
  }

  &__image {
    background: transparent url("./no-soil.svg") no-repeat center;
    height: 100px;
    margin: 30px;
  }
}

// Index

@media (max-width: 767px) {
  .soil-dashboard-soil-widgets {
    display: flex;
    flex-direction: column;

    &__relief {
      order: 1;
    }

    &__soil-moisture {
      order: 3;
    }

    &__description {
      order: 2;
    }
  }
}
