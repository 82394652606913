// scss-lint:disable PropertyCount
.plant-threats-index-wrapper {
  height: 100%;
}

.plant-threats-index {
  align-items: stretch;
  background: #fff;
  bottom: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  padding-top: 40px;
  position: absolute;
  top: 0;
  width: 100%;

  &__create-standard-plant-threats {
    background-color: #0071cd;
    color: #fff;
    font-size: 14px;
    height: 32px;
    padding-bottom: 3px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    position: absolute;
    text-align: center;
    top: 40px;
    width: 100%;
  }

  &__create-standard-plant-threats-btn {
    border-bottom: dotted 1px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
    outline: none;
    padding: 0;

    &:hover,
    &:focus,
    &:active:focus {
      border-bottom: dotted 1px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      outline: none;
      text-decoration: none;
    }
  }

  &__create_new_button {
    height: 32px;
    margin-top: 2px;
    padding-bottom: 6px;
    padding-top: 6px;
  }

  &__edit-btn {
    height: 32px;
  }

  &__visible-message-wrapper {
    opacity: 0;
    padding-left: 12px;
    padding-right: 17px;
    position: absolute;
    width: 100%;
  }

  &__visible-message-wrapper-enter-active {
    opacity: 1;
    z-index: 1000;
  }

  &__visible-message-wrapper-exit {
    opacity: 1;
    z-index: 1000;
  }

  &__visible-message-wrapper-exit-active {
    opacity: 0;
    transition: opacity 600ms linear;
    z-index: 1000;
  }

  &__visible-message {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    height: 74px;
    justify-content: flex-start;
    line-height: 44px;
    text-align: center;
    width: 100%;
  }

  &__visible-message-icon {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-right: 16px;
    width: 50px;
  }

  &__item-visible-icon {
    cursor: pointer;
    margin-left: 20px;
    outline: none;
  }

  &__item-visible-popover > .popover-content {
    padding: 6px 12px;
  }
}

@media screen and (max-width: 992px) {
  .plant-threats-index {
    flex-direction: column;
    position: relative;

    .plant-threats-catalog {
      flex-direction: column;
      position: relative;
    }

    .plant-threats-catalog__left-column {
      border-right: 0;
      height: 400px;
      width: 100%;
    }

    .plant-threats-catalog__list {
      border-bottom: 1px solid #eee;
      height: 225px;
    }
  }
}
