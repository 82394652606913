.field-work-results-field-preview {
  &__fields-list-header {
    padding-top: 14px;
  }

  &__map {
    height: 450px;
    width: 100%;

    canvas {
      height: 397px !important; // scss-lint:disable ImportantRule
    }
  }

  &__map-info {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-left: 10px;
    padding-top: 10px;
    position: absolute;
    text-transform: uppercase;
    z-index: 10000;
  }

  &__field-name {
    font-size: 24px;
  }

  &__form-button {
    bottom: 10px;
    left: 20px;
    position: absolute;
  }

  &__map-gl-wrapper {
    border: solid 1px rgba(0, 0, 0, 0.7);
    height: inherit;
    width: inherit;
  }

  &__map-gl {
    height: inherit;
    width: inherit;
  }

  &__applied-additional-fields {
    bottom: 65px;
    left: 20px;
    position: absolute;

    width: 25%;

    @media (max-width: 991px) {
      width: 50%;
    }
  }

  &__yield-additional-fields {
    bottom: 145px;
    left: 35px;
    position: absolute;

    width: 25%;

    @media (max-width: 991px) {
      width: 50%;
    }
  }

  &__vra-thumb {
    height: 100px;

    canvas {
      height: 97px !important; // scss-lint:disable ImportantRule
    }
  }

  .form-group {
    margin-bottom: 8px;
  }
}

.date-picker-fwr-work-date {
  cursor: pointer;
}
