.map-legend-gradient {
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  height: 90px;
  padding: 8px;

  &_dark {
    background: transparent;
    color: #fff;
  }

  &__color {
    border-radius: 4px;
    width: 4px;
  }

  &__values {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 10px;
    margin-right: 4px;
    max-width: 60px;
    text-align: right;
    white-space: nowrap;
  }
}
