.map-gradient-ndvi-scale {
  &__white {
    background: #fff;
    background-image: url("./gradientNdviScale.png");
    background-position: 9px center;
    background-repeat: no-repeat;
    border-radius: 4px;
    color: #000;
    padding-bottom: 10px;
    padding-left: 13px;
    padding-right: 5px;
    padding-top: 10px;

    &__table {
      line-height: 1.4;
    }

    &__row:first-child {
      td {
        padding-top: 0;
      }
    }

    &__row:last-child {
      td {
        padding-bottom: 0;
      }
    }

    &__color,
    &__value {
      font-size: 9px;
      padding-bottom: 14px;
      padding-left: 3px;
      padding-top: 14px;
    }

    &__table-cell_first {
      padding-top: 0;
    }

    &__table-cell_last {
      padding-top: 0;
    }
  }

  &__dark-transparent {
    background: rgba(0, 0, 0, 0.4);
    background-image: url("./gradientNdviScale.png");
    background-position: 4px center;
    background-repeat: no-repeat;
    color: #fff;
    padding: 4px;

    &__table {
      line-height: 1.4;
    }

    &__row:first-child {
      td {
        padding-top: 0;
      }
    }

    &__row:last-child {
      td {
        padding-bottom: 0;
      }
    }

    &__color,
    &__value {
      font-size: 9px;
      padding-bottom: 14px;
      padding-left: 3px;
      padding-top: 14px;
    }

    &__table-cell_first {
      padding-top: 0;
    }

    &__table-cell_last {
      padding-top: 0;
    }
  }
}
