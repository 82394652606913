@mixin wrap-center {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.date-time-input {
  $link-color: #0071cd;
  $hover-link-color: #23527c;

  @include wrap-center;
  justify-content: space-between;

  &__date,
  &__time {
    @include wrap-center;
    padding-bottom: 2px;
  }

  &__date {
    flex-grow: 80;
  }

  &__time {
    flex-grow: 40;
  }

  &__day {
    flex-grow: 20;
  }

  &__month {
    flex-grow: 30;
    padding: 0 2px;
  }

  &__year {
    flex-grow: 30;
  }

  &__hour {
    flex-grow: 20;
  }

  &__minute {
    flex-grow: 20;
  }

  &__second {
    flex-grow: 20;
  }

  &__date-and-time-divider {
    flex-grow: 5;
  }

  &__hour-and-minute-divider {
    flex-grow: 3;
  }

  &__minute-and-second-divider {
    flex-grow: 3;
  }

  &__set-current-time {
    color: $link-color;
    position: absolute;
    right: -10px;

    &:hover {
      color: $hover-link-color;
      cursor: pointer;
    }
  }

  .form-control {
    border-radius: 2px;
    padding: 2px;
  }
}
