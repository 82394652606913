.field-work-results-data-grid-map {
  &__map-gl {
    height: 400px;
    width: 100%;
  }

  &__point-info {
    color: #fff;
    left: 10px;
    pointer-events: none;
    position: absolute;
    text-align: right;
    top: 40px;
    z-index: 100;

    &::before {
      background-image: linear-gradient(230deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
      bottom: -12px;
      content: "";
      filter: blur(10px);
      left: -30px;
      pointer-events: none;
      position: absolute;
      right: -10px;
      top: -3px;
      z-index: -1;
    }
  }

  &__raw-data-switcher {
    float: right;
    margin-bottom: 5px;
  }

  &__point-info-prop {
    color: #fff;
    margin-left: 35px;
    margin-top: 4px;
  }

  &__point-info-prop-value {
    color: #fff;
    font-size: 25px;
    font-weight: 900;
    margin-right: 4px;
  }

  &__point-info-prop-units {
    color: rgba(255, 255, 255, 0.3);
    font-weight: 900;
  }

  &__vra-main {
    height: 350px;
  }

  &__vra-thumb {
    height: 100px;
  }
}

.field-work-results-row {
  &__map-header {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }

  &__map-value-header {
    color: rgb(255, 255, 255);
    margin-left: 10px;
  }

  &__map-value-value {
    color: rgb(255, 255, 255);
    display: flex;
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;
  }

  &__map-value-units {
    color: rgb(255, 255, 255);
    font-size: 20px;
  }

  &__map-value-tooltip {
    cursor: help;
    display: flex;
    margin-left: 5px;
  }
}

.field-work-results-raw-map {
  &__map-gl {
    height: 400px;
    width: 100%;
  }

  &__setting-btn,
  &__filter-btn {
    height: 30px;
    width: 30px;

    &:hover {
      background-color: #fff !important; // scss-lint:disable ImportantRule
      border-color: #d9d9d9;
    }
  }

  &__filter-cont {
    width: 200px;
  }

  &__filter-item {
    margin-bottom: 20px;
    position: relative;

    label {
      width: 100%;
    }
  }

  &__filter-inputs {
    display: flex;
    justify-content: space-between;
  }

  &__out-color {
    bottom: -6px;
    position: absolute;
    right: 0;
  }

  &__filter-reset {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
